import React, { useEffect, useState } from "react";
import { Card, Container, Button, Col, Row, Form } from "react-bootstrap";
import "../../../../scss/roomList.scss";
import DatePicker from "react-datepicker";
import { Link, useNavigate } from "react-router-dom";
import OffEveryApi from "../../../../api/OffEveryApi";
import Swal from "sweetalert2";
import firstPage from "../../../../api/firstPage";
import { Box, makeStyles, Paper } from "@material-ui/core";
import image from "../../../../assets/icon/upload2.png";
import PhotoCamera from "@mui/icons-material/PhotoCamera";

const useStyle = makeStyles({
  image: {
    borderRadius: 0,
  },
});

const FranchisePage = () => {
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [type, setType] = useState("edit");
  const [oldImg, setOldImg] = useState("");
  const [state, setstate] = useState({
    file: "",
    imagePreviewUrl: "",
  });
  const classes = useStyle();

  useEffect(() => {
    if (localStorage.getItem("access-token") === null) {
      navigate("/admin/login");
    }
  }, []);
  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    const res = await firstPage.getFranchise();
    if (res?.data?.elements) {
      setOldImg(res?.data?.elements[0].imageUrl);
      setId(res?.data?.elements[0].id);
    } else {
      setType("create");
    }
  };
  const onSubmit = async () => {
    const data = new FormData();
    data.append("id", id);
    data.append("file", state.file);
    data.append("fileName", state.file.name ? state.file.name : "");
    data.append("imageUrl", state.imagePreviewUrl);
    if (type === "edit") {
      const res = await firstPage.updateFranchise(data);
      if (res) {
        alertSweet("สำเร็จ", "กำหนดสำเร็จ", "success");
      }
    } else {
      const res = await firstPage.createFranchise(data);
      if (res) {
        alertSweet("สำเร็จ", "กำหนดสำเร็จ", "success");
      }
    }
  };
  const alertSweet = (title, text, icon) => {
    Swal.fire({
      title: title,
      icon: icon,
      text: text,
    });
  };

  const uploadSingleFile = (e) => {
    setstate({
      imagePreviewUrl: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
  };

  return (
    <div className="HomeAdmin">
      <Container>
        <div className="text-center">
          <br />
          <Card className="box-shadow mt-3">
            <Card.Body>
              <h3 className="mb-0 text-left">วิดีโอ</h3>
              <br />
              <Row>
                <Paper
                  style={{
                    width: "250px",
                    margin: "auto",
                    // marginTop: "10%",
                  }}
                >
                  <Box
                    width="250px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <input
                      type="file"
                      accept="image/*"
                      name="image"
                      id="file"
                      onChange={uploadSingleFile}
                      style={{ display: "none" }}
                    />
                    <p className="mt-1 mb-0"> เพิ่มรูปภาพ </p>

                    <img
                      src={
                        state.imagePreviewUrl
                          ? state.imagePreviewUrl
                          : type === "edit"
                          ? oldImg
                          : image
                      }
                      className={classes.image}
                      id="output"
                      width="200"
                      alt="test"
                    />
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    padding="10px 20px"
                  >
                    <label htmlFor="file" style={{ cursor: "pointer" }}>
                      <PhotoCamera />
                    </label>
                  </Box>
                </Paper>
              </Row>
              <div className="p-2">
                <Button
                  className="m-2"
                  style={{ width: "40%" }}
                  onClick={() => onSubmit()}
                  // onClick={() => handleShow()}
                  // disabled={time.length === 0}
                >
                  ยืนยัน
                </Button>
                <Link
                  to={{
                    pathname: "/admin/setting",
                  }}
                >
                  <Button
                    className="m-2"
                    variant="outline-secondary"
                    style={{ width: "40%" }}
                  >
                    Back
                  </Button>
                </Link>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default FranchisePage;
