import React, { useEffect, useState } from "react";
import { Card, Container, Button, Col, Row, Form } from "react-bootstrap";
import "../../../../scss/roomList.scss";
import DatePicker from "react-datepicker";
import { Link, useNavigate } from "react-router-dom";
import PromotionApi from "../../../../api/promotion";
import Swal from "sweetalert2";
const PromotionAdd = () => {
  const navigate = useNavigate();
  const [promotion, setPromotion] = useState(0);
  const [promotionName, setPromotionName] = useState("");

  useEffect(() => {
    if (localStorage.getItem("access-token") === null) {
      navigate("/admin/login");
    }
  }, []);
  const onSubmit = async () => {
    const res = await PromotionApi.create({
      promotion: promotion,
      promotionName: promotionName,
    });
    if (res) {
      alertSweet("สำเร็จ", "กำหนดสำเร็จ", "success");
    }
  };
  const alertSweet = (title, text, icon) => {
    Swal.fire({
      title: title,
      icon: icon,
      text: text,
    });
  };

  const onChangeDis = (e) => {
    if (e > 100) {
      setPromotion(100);
    } else {
      setPromotion(e);
    }
  };
  return (
    <div className="HomeAdmin">
      <Container>
        <div className="text-center">
          <br />
          <Card className="box-shadow mt-3">
            <Card.Body>
              <h3 className="mb-0 text-left">Promotion</h3>
              <br />
              <Row>
                <Col>
                  <Form.Group
                    className="mb-3 text-left"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Promotion Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Promotion Name"
                      onChange={(e) => setPromotionName(e.target.value)}
                      value={promotionName}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3 text-left"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Discount</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter Discount"
                      value={promotion === 0 ? "" : promotion}
                      onChange={(e) => onChangeDis(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="p-2">
                <Button
                  className="m-2"
                  style={{ width: "40%" }}
                  onClick={() => onSubmit()}
                  // onClick={() => handleShow()}
                  // disabled={time.length === 0}
                >
                  ยืนยัน
                </Button>
                <Link
                  to={{
                    pathname: "/admin/promotion",
                  }}
                >
                  <Button
                    className="m-2"
                    variant="outline-secondary"
                    style={{ width: "40%" }}
                  >
                    Back
                  </Button>
                </Link>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default PromotionAdd;
