import http from "./http-common";

const login = (data) => http.post(`/auth/login`, data);

const getUser = (data) => http.get(`/user`, data);

export default {
  login,
  getUser
};
