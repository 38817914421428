import http from "./http-common";

const path = "/category";

const getAll = (pageNumber, perPage) =>
  http.get(path, {
    params: {
      page: pageNumber,
      per_page: perPage,
    },
  });

export default {
  getAll,
};
