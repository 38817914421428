import http from "./http-common";

// const getVideo = () => http.get(`/video/get`);

const getVideo = (pageNumber, perPage) =>
  http.get("/video/get", {
    params: {
      page: pageNumber,
      per_page: perPage,
    },
  });
const createVideo = (data) => http.post(`/video/create`, data);
const updateVideo = (data) => http.put(`/video/update`, data);

const getFranchise = (pageNumber, perPage) =>
  http.get("/franchise/get", {
    params: {
      page: pageNumber,
      per_page: perPage,
    },
  });
const createFranchise = (data) => http.post(`/franchise/create`, data);
const updateFranchise = (data) => http.put(`/franchise/update`, data);

const getNews = (pageNumber, perPage) =>
  http.get("/news/get", {
    params: {
      page: pageNumber,
      per_page: perPage,
    },
  });
const getNewsById = (id) => http.get(`/news/${id}`);
const createNews = (data) => http.post(`/news/create`, data);
const updateNews = (data) => http.put(`/news/update`, data);
const removeNews = (id) => http.delete(`/news/${id}`);

const getText = (pageNumber, perPage) =>
  http.get("/escaperoom/get", {
    params: {
      page: pageNumber,
      per_page: perPage,
    },
  });

const createText = (data) => http.post(`/escaperoom/create`, data);
const updateText = (data) => http.put(`/escaperoom/update`, data);

const getReview = (pageNumber, perPage) =>
  http.get("/review/get", {
    params: {
      page: pageNumber,
      per_page: perPage,
    },
  });
const createReview = (data) => http.post(`/review/create`, data);
const updateReview = (data) => http.put(`/review/update`, data);

const getPhotoPromotion = (pageNumber, perPage) =>
  http.get("/photopromotion/get", {
    params: {
      page: pageNumber,
      per_page: perPage,
    },
  });
const createPhotoPromotion = (data) =>
  http.post(`/photopromotion/create`, data);
const updatePhotoPromotion = (data) => http.put(`/photopromotion/update`, data);
const removePhotoPromotion = (id) => http.delete(`/photopromotion/${id}`);

export default {
  getVideo,
  createVideo,
  updateVideo,
  getFranchise,
  createFranchise,
  updateFranchise,
  getNews,
  getNewsById,
  createNews,
  updateNews,
  removeNews,
  getText,
  createText,
  updateText,
  getReview,
  createReview,
  updateReview,
  getPhotoPromotion,
  createPhotoPromotion,
  updatePhotoPromotion,
  removePhotoPromotion,
};
