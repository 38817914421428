import http from "./http-common";

const path = "/room";

const getAll = (pageNumber, perPage) =>
  http.get(path, {
    params: {
      page: pageNumber,
      per_page: perPage,
    },
  });

const create = (data) => http.post(`${path}/create`, data);

const update = (data) => http.put(`${path}/update`, data);

const getRoom = (id) => http.get(`${path}/${id}`);

const remove = (id) => http.delete(`${path}/${id}`);

const getTime = () => http.get(`dateTime`);

export default {
  getAll,
  create,
  getRoom,
  remove,
  update,
  getTime,
};
