import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import { Button, Card, Container, Form } from "react-bootstrap";
import "../../../../scss/login.scss";
import api from "../../../../api/queueApi";
import apiLogin from "../../../../api/loginApi";
import http from "../../../../api/http-common";
import Swal from "sweetalert2";

const Login = () => {
  const navigate = useNavigate();
  // const history = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  useEffect(() => {
    if (localStorage.getItem("access-token") !== null) {
      navigate("/admin/home");
    }
  }, []);

  const onLogin = async () => {
    try {
      const response = await apiLogin.login({
        email: username,
        password: password,
      });
      if (response) {
        http.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;
        localStorage.setItem("access-token", response.data.token);
        localStorage.setItem("user-info", JSON.stringify(response.data.info));
        localStorage.setItem(
          "user-permissions",
          JSON.stringify(response.data.permissions)
        );
        navigate("/admin/home");
      }
    } catch (e) {
      alertSweet(
        "ไม่สำเร็จ",
        "อีเมลหรือรหัสผ่านไม่ถูกต้อง กรุณากรอกใหม่อีกครั้ง",
        "error"
      );
    }
  };

  const alertSweet = (title, text, icon) => {
    Swal.fire({
      title: title,
      icon: icon,
      text: text,
    });
  };

  return (
    <div className="login">
      <Container>
        <div className="text-center">
          <Card className="card-login mt-5 m-auto">
            <Card.Body>
              <Form>
                <Form.Group
                  className="mb-3 text-left"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3 text-left"
                  controlId="formBasicPassword"
                >
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <Button
                  variant="primary"
                  type="button"
                  onClick={() => onLogin()}
                >
                  เข้าสู่ระบบ
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default Login;
