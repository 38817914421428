import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const dataPayment = {
  description: "2 days 1 night",
  amount: "4444",
  currencyCode: "THB",
  paymentChannel: ["CC", "IPP", "BANK"],
  locale: "th",
  frontendReturnUrl: window.location.href,
};

function ModalCheck(props) {
  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.detail}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            ยกเลิก
          </Button>
          <Button
            variant="primary"
            // onClick={() => testApi()}
          >
            ยืนยัน
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalCheck;
