import http from "./http-common";

const path = "/genKey";

const getAll = (pageNumber, perPage) =>
  http.get(path, {
    params: {
      page: pageNumber,
      per_page: perPage,
    },
  });

const genLink = (data) => http.post(path + "/tokenPayload", data);

const testBook = (data) => http.post("/booking/create", data);

const checkPayment = (data) => http.post(path + "/tokenCheckPayment", data);

export default {
  getAll,
  genLink,
  checkPayment,
    testBook
};
