import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Form, Button } from "react-bootstrap";
import "../../../../../scss/roomList.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";

import PhotoCamera from "@mui/icons-material/PhotoCamera";
import image from "../../../../../assets/icon/upload2.png";
import { Box, makeStyles, Paper } from "@material-ui/core";
import api from "../../../../../api/roomApi";
import apiCat from "../../../../../api/cat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeftLong } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import firstPage from "../../../../../api/firstPage";

const useStyle = makeStyles({
  image: {
    borderRadius: 0,
  },
});

const NewsEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const type = location.state.type;
  const newsId = location.state.id;
  const [state, setstate] = useState({
    file: "",
    imagePreviewUrl: "",
  });
  const [topic, setTopic] = useState("");
  const [detail, setDetail] = useState("");
  const [link, setLink] = useState("");

  const [oldImg, setOldImg] = useState("");
  const classes = useStyle();

  const uploadSingleFile = (e) => {
    setstate({
      imagePreviewUrl: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
  };

  useEffect(() => {
    if (type === "edit") {
      fetchData();
    } else {
      // fetchCatDefault();
    }
  }, []);

  const fetchData = async () => {
    try {
      const res = await firstPage.getNewsById(newsId);
      const data = res.data;
      if (res) {
        setTopic(data.title);
        setDetail(data.detail);
        setLink(data.link);
        setOldImg(data.imageUrl);
      }
    } catch (error) {
      // ..
    }
  };

  useEffect(() => {
    if (localStorage.getItem("access-token") === null) {
      navigate("/admin/login");
    }
  }, []);

  const createRoom = async () => {
    const data = new FormData();
    data.append("title", topic);
    data.append("detail", detail);
    data.append("link", link);
    if (type === "edit") {
      data.append("id", newsId);
    }
    if (state.file !== "") {
      data.append("file", state.file);
      data.append("fileName", state.file.name ? state.file.name : "");
      data.append("imageUrl", state.imagePreviewUrl);
      // data.append("type", "escaperoom");
    }
    try {
      const response =
        type === "edit"
          ? await firstPage.updateNews(data)
          : await firstPage.createNews(data);
      if (response) {
        alertSweet("สำเร็จ", "สร้างรายการสำเร็จ", "success");
        setDetail("");
        setstate({
          file: "",
          imagePreviewUrl: "",
        });
        setOldImg("");
      }
      if (type === "edit") {
        navigate("/admin/setting/news");
      }
    } catch (error) {
      alertSweet("ไม่สำเร็จ", "สร้างห้องไม่สำเร็จ", "error");
    }
  };

  const alertSweet = (title, text, icon) => {
    Swal.fire({
      title: title,
      icon: icon,
      text: text,
    });
  };

  return (
    <div className="HomeAdmin">
      <Container>
        <div className="text-left">
          <Link
            to={{
              pathname: "/admin/setting/news",
            }}
          >
            <Button className="mt-3">
              <FontAwesomeIcon
                icon={faLeftLong}
                style={{ fontSize: 14, marginRight: 5 }}
                className="set-cursor"
                color={"#fff"}
              />
              ย้อนกลับ
            </Button>
          </Link>
        </div>
        <div className="text-center">
          <Card className="box-shadow mt-3">
            <Card.Body>
              <div>
                <Row>
                  <Col>
                    <Paper
                      style={{
                        width: "250px",
                        margin: "auto",
                        marginTop: "10%",
                      }}
                    >
                      <Box
                        width="250px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                      >
                        <input
                          type="file"
                          accept="image/*"
                          name="image"
                          id="file"
                          onChange={uploadSingleFile}
                          style={{ display: "none" }}
                        />
                        <p className="mt-1 mb-0"> เพิ่มรูปภาพ </p>

                        <img
                          src={
                            state.imagePreviewUrl
                              ? state.imagePreviewUrl
                              : type === "edit"
                              ? oldImg
                              : image
                          }
                          className={classes.image}
                          id="output"
                          width="200"
                          alt="test"
                        />
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        padding="10px 20px"
                      >
                        <label htmlFor="file" style={{ cursor: "pointer" }}>
                          <PhotoCamera />
                        </label>
                      </Box>
                    </Paper>
                  </Col>
                  <Col>
                    <Form className="w-75 text-left mt-5">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>หัวข้อ</Form.Label>
                        <Form.Control
                          type="text"
                          value={topic}
                          placeholder="กรอกชื่อหัวข้อ"
                          onChange={(e) => setTopic(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>ข้อมูลแบบย่อ</Form.Label>
                        <Form.Control
                          type="text"
                          value={detail}
                          placeholder="กรอกข้อมูลแบบย่อ"
                          onChange={(e) => setDetail(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Link</Form.Label>
                        <Form.Control
                          type="text"
                          value={link}
                          placeholder="กรอก Link"
                          onChange={(e) => setLink(e.target.value)}
                        />
                      </Form.Group>
                    </Form>
                  </Col>
                </Row>
              </div>
              <div className="p-2 mt-5">
                <Button
                  className="m-2"
                  style={{ width: "20%" }}
                  onClick={() => createRoom()}
                >
                  ยืนยัน
                </Button>
                <Link
                  to={{
                    pathname: "/admin/setting/news",
                  }}
                >
                  <Button
                    className="m-2"
                    variant="outline-secondary"
                    style={{ width: "20%" }}
                  >
                    ย้อนกลับ
                  </Button>
                </Link>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default NewsEdit;
