import React, { useEffect, useState } from "react";
import { Card, Container, Button, Col, Row } from "react-bootstrap";
import "../../../../scss/roomList.scss";
import DatePicker from "react-datepicker";
import { Link, useNavigate } from "react-router-dom";
import dayOffApi from "../../../../api/dayOffApi";
import Swal from "sweetalert2";
const DayOff = () => {
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    if (localStorage.getItem("access-token") === null) {
      navigate("/admin/login");
    }
  }, []);

  const onSubmit = async () => {
    const res = await dayOffApi.create({ date: date });
    if (res) {
      alertSweet("สำเร็จ", "กำหนดสำเร็จ", "success");
    }
  };
  const alertSweet = (title, text, icon) => {
    Swal.fire({
      title: title,
      icon: icon,
      text: text,
    });
  };
  return (
    <div className="HomeAdmin">
      <Container>
        <div className="text-center">
          <br />
          <Card className="box-shadow mt-3">
            <Card.Body>
              <h3 className="mb-0 text-left">วันหยุด</h3>
              <br />
              <Row>
                <DatePicker
                  selected={date}
                  onChange={(date) => setDate(date)}
                  inline
                  minDate={new Date()}
                />
              </Row>
              <div className="p-2">
                <Button
                  className="m-2"
                  style={{ width: "40%" }}
                  onClick={() => onSubmit()}
                  // onClick={() => handleShow()}
                  // disabled={time.length === 0}
                >
                  ยืนยัน
                </Button>
                <Link
                  to={{
                    pathname: "/admin/dayOfflist",
                  }}
                >
                  <Button
                    className="m-2"
                    variant="outline-secondary"
                    style={{ width: "40%" }}
                  >
                    Back
                  </Button>
                </Link>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default DayOff;
