import * as React from "react";
import Slider from "../../../components/slider/slider";
import "../../../scss/home.scss";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import apiRoom from "../../../api/roomApi";

function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll", reveal);

export function Home() {
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(true);

  // useEffect(() => {
  //   if (reload) {
  //     window.location.reload();
  //   }
  //   setReload(false);
  // }, []);

  useEffect(() => {
    apiRoom
      .getAll()
      .then((r) => (r.data.elements ? setData(r.data.elements) : setData([])));
  }, []);

  useEffect(() => {
    localStorage.clear();
  }, []);

  const Investigation = ({ mockData }) => {
    const resultsRender = [];

    for (var i = 0; i < mockData.length; i += 3) {
      resultsRender.push(
        <section>
          <div className="container reveal">
            <div className="text-container">
              <div className="text-box">
                <Row>
                  {mockData
                    .filter((r) => r.categoryName === "Investigation")
                    .slice(i, i + 3)
                    .map((r) => (
                      <Col className="col-margin">
                        <Link
                          to={{
                            pathname: "/selectqueue",
                            search: `?roomId=${r.id}`,
                          }}
                        >
                          <Card
                            style={{ width: "18rem" }}
                            className="box-shadow card-zoom"
                          >
                            <Card.Img
                              variant="top"
                              src={r.imageUrl}
                              height={400}
                            />
                            <Card.Body>
                              <Card.Title>{r.name}</Card.Title>
                              <Card.Text className="crop">
                                {r.description}
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </Link>
                      </Col>
                    ))}
                </Row>
              </div>
            </div>
          </div>
        </section>
      );
    }
    return <div>{resultsRender}</div>;
  };

  const Escape = ({ mockData }) => {
    const resultsRender = [];

    for (var i = 0; i < mockData.length; i += 3) {
      resultsRender.push(
        <section>
          <div className="container reveal">
            <div className="text-container">
              <div className="text-box">
                <Row>
                  {mockData
                    .filter((r) => r.categoryName === "escape room")
                    .slice(i, i + 3)
                    .map((r) => (
                      <Col className="col-margin">
                        <Link
                          to={{
                            pathname: "/selectqueue",
                            search: `?roomId=${r.id}`,
                          }}
                        >
                          <Card
                            style={{ width: "18rem" }}
                            className="box-shadow card-zoom"
                          >
                            <Card.Img
                              variant="top"
                              src={r.imageUrl}
                              height={400}
                            />
                            <Card.Body>
                              <Card.Title>{r.name}</Card.Title>
                              <Card.Text className="crop">
                                {r.description}
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </Link>
                      </Col>
                    ))}
                </Row>
              </div>
            </div>
          </div>
        </section>
      );
    }
    return <div>{resultsRender}</div>;
  };
  return (
    <div>
      <Slider />
      <Container>
        <span style={{ fontSize: 40, color: "#fff" }}>Escape Room</span>
        <Escape mockData={data} />

        <span style={{ fontSize: 40, color: "#fff" }}>Investigation</span>
        <Investigation mockData={data} />
      </Container>
    </div>
  );
}
