import React, { useEffect, useState } from "react";
import { Card, Container, Button, Col, Row, Form } from "react-bootstrap";
import "../../../../scss/roomList.scss";
import DatePicker from "react-datepicker";
import { Link, useNavigate } from "react-router-dom";
import OffEveryApi from "../../../../api/OffEveryApi";
import Swal from "sweetalert2";
const OffEvery = () => {
  const navigate = useNavigate();
  const [day, setDay] = useState("");

  useEffect(() => {
    if (localStorage.getItem("access-token") === null) {
      navigate("/admin/login");
    }
  }, []);
  const onSubmit = async () => {
    const res = await OffEveryApi.update({
      id: "7bfbd12a-e29a-4091-9d54-880ec591d768",
      day: day,
    });
    if (res) {
      alertSweet("สำเร็จ", "กำหนดสำเร็จ", "success");
    }
  };
  const alertSweet = (title, text, icon) => {
    Swal.fire({
      title: title,
      icon: icon,
      text: text,
    });
  };
  return (
    <div className="HomeAdmin">
      <Container>
        <div className="text-center">
          <br />
          <Card className="box-shadow mt-3">
            <Card.Body>
              <h3 className="mb-0 text-left">วันหยุดในสัปดาห์</h3>
              <br />
              <Row>
                <Form.Group className="mb-3 w-50 m-auto">
                  <Form.Label>
                    <span>เลือกวันหยุด</span>
                  </Form.Label>
                  <Form.Select onChange={(e) => setDay(e.target.value)}>
                    <option value="Unset">ไม่หยุด</option>
                    <option value="Sunday">วันอาทิตย์</option>
                    <option value="Monday">วันจันทร์</option>
                    <option value="Tuesday">วันอังคาร</option>
                    <option value="Wednesday">วันพุธ</option>
                    <option value="Thursday">วันพฤหัสบดี</option>
                    <option value="Friday">วันศุกร์</option>
                  </Form.Select>
                </Form.Group>
              </Row>
              <div className="p-2">
                <Button
                  className="m-2"
                  style={{ width: "40%" }}
                  onClick={() => onSubmit()}
                  // onClick={() => handleShow()}
                  // disabled={time.length === 0}
                >
                  ยืนยัน
                </Button>
                <Link
                  to={{
                    pathname: "/admin/home",
                  }}
                >
                  <Button
                    className="m-2"
                    variant="outline-secondary"
                    style={{ width: "40%" }}
                  >
                    Back
                  </Button>
                </Link>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default OffEvery;
