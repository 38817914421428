import http from "./http-common";

const path = "/booking";

const getAll = (pageNumber, perPage) =>
  http.get(path, {
    params: {
      page: pageNumber,
      per_page: perPage,
    },
  });

const update = (data) => http.put(`${path}/update`, data);
const remove = (id) => http.delete(`${path}/${id}`);

export default {
  getAll,
  update,
  remove,
};
