import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Form, Button } from "react-bootstrap";
import "../../../../scss/roomList.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";

import PhotoCamera from "@mui/icons-material/PhotoCamera";
import image from "../../../../assets/icon/upload2.png";
import { Box, makeStyles, Paper } from "@material-ui/core";
import api from "../../../../api/roomApi";
import apiCat from "../../../../api/cat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeftLong } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { ca } from "date-fns/locale";

const useStyle = makeStyles({
  image: {
    borderRadius: 0,
  },
});

const RoomEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const type = location.state.type;
  const roomId = location.state.id;
  const [state, setstate] = useState({
    file: "",
    imagePreviewUrl: "",
  });
  const [roomName, setRoomName] = useState("");
  const [price, setPrice] = useState(990);
  const [twoPeople, setTwoPeople] = useState(0);
  const [threePeople, setThreePeople] = useState(0);
  const [fourPeople, setFourPeople] = useState(0);
  const [fivePeople, setFivePeople] = useState(0);
  const [sixPeople, setSixPeople] = useState(0);
  const [detail, setDetail] = useState("");
  const [oldImg, setOldImg] = useState("");
  const [typeRoom, setTypeRoom] = useState([]);
  const [cat, setCat] = useState("");
  const classes = useStyle();

  const uploadSingleFile = (e) => {
    setstate({
      imagePreviewUrl: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
  };

  useEffect(() => {
    if (type === "edit") {
      fetchData();
    } else {
      fetchCatDefault();
    }
  }, []);

  useEffect(() => {
    fetchCat();
  }, []);

  const fetchCat = async () => {
    const resCat = await apiCat.getAll();
    setTypeRoom(resCat.data.elements);
  };

  const fetchCatDefault = async () => {
    const resCat = await apiCat.getAll();
    setCat(resCat.data.elements[0].id);
  };

  const fetchData = async () => {
    try {
      const res = await api.getRoom(roomId);
      if (res) {
        setPrice(990);
        setDetail(res.data.description);
        setRoomName(res.data.name);
        setOldImg(res.data.imageUrl);
        setCat(res.data.categoryId);
        setTwoPeople(res.data.twoPeople);
        setThreePeople(res.data.threePeople);
        setFourPeople(res.data.fourPeople);
        setFivePeople(res.data.fivePeople);
        setSixPeople(res.data.sixPeople);
      }
    } catch (error) {
      // ..
    }
  };

  useEffect(() => {
    if (localStorage.getItem("access-token") === null) {
      navigate("/admin/login");
    }
  }, []);

  const createRoom = async () => {
    const data = new FormData();
    data.append("name", roomName);
    data.append("price", price);
    data.append("description", detail);
    data.append("categoryId", cat);
    data.append("onePeople", 0);
    data.append("twoPeople", twoPeople);
    data.append("threePeople", threePeople);
    data.append("fourPeople", fourPeople);
    data.append("fivePeople", fivePeople);
    data.append(
      "sixPeople",
      cat === "36ca416f-c719-4260-92cc-16ead0d5861e" ? sixPeople : 0
    );
    if (type === "edit") {
      data.append("id", roomId);
    }
    if (state.file !== "") {
      data.append("file", state.file);
      data.append("fileName", state.file.name ? state.file.name : "");
      data.append("imageUrl", state.imagePreviewUrl);
      // data.append("type", "escaperoom");
    }
    try {
      const response =
        type === "edit" ? await api.update(data) : await api.create(data);
      if (response) {
        alertSweet("สำเร็จ", "สร้างรายการสำเร็จ", "success");
        setRoomName("");
        setPrice(990);
        setDetail("");
        setstate({
          file: "",
          imagePreviewUrl: "",
        });
        setOldImg("");
      }
      if (type === "edit") {
        navigate("/admin/roomlist");
      }
    } catch (error) {
      alertSweet("ไม่สำเร็จ", "สร้างห้องไม่สำเร็จ", "error");
    }
  };

  const alertSweet = (title, text, icon) => {
    Swal.fire({
      title: title,
      icon: icon,
      text: text,
    });
  };

  const onChangeNumber = (event, name) => {
    const re = /^[0-9\b]+$/;

    if (event.target.value === "" || re.test(event.target.value)) {
      if (name === "twoPeople") {
        setTwoPeople(event.target.value);
      }
      if (name === "threePeople") {
        setThreePeople(event.target.value);
      }
      if (name === "fourPeople") {
        setFourPeople(event.target.value);
      }
      if (name === "fivePeople") {
        setFivePeople(event.target.value);
      }
      if (name === "sixPeople") {
        setSixPeople(event.target.value);
      }
    }
  };

  return (
    <div className="HomeAdmin">
      <Container>
        <div className="text-left">
          <Link
            to={{
              pathname: "/admin/roomlist",
            }}
          >
            <Button className="mt-3">
              <FontAwesomeIcon
                icon={faLeftLong}
                style={{ fontSize: 14, marginRight: 5 }}
                className="set-cursor"
                color={"#fff"}
              />
              ย้อนกลับ
            </Button>
          </Link>
        </div>
        <div className="text-center">
          <Card className="box-shadow mt-3">
            <Card.Body>
              <div>
                <Row>
                  <Col>
                    <Paper
                      style={{
                        width: "250px",
                        margin: "auto",
                        marginTop: "10%",
                      }}
                    >
                      <Box
                        width="250px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                      >
                        <input
                          type="file"
                          accept="image/*"
                          name="image"
                          id="file"
                          onChange={uploadSingleFile}
                          style={{ display: "none" }}
                        />
                        <p className="mt-1 mb-0"> เพิ่มรูปภาพ </p>

                        <img
                          src={
                            state.imagePreviewUrl
                              ? state.imagePreviewUrl
                              : type === "edit"
                              ? oldImg
                              : image
                          }
                          className={classes.image}
                          id="output"
                          width="200"
                          alt="test"
                        />
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        padding="10px 20px"
                      >
                        <label htmlFor="file" style={{ cursor: "pointer" }}>
                          <PhotoCamera />
                        </label>
                      </Box>
                    </Paper>
                  </Col>
                  <Col>
                    <Form className="w-75 text-left mt-5">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>ชื่อห้อง</Form.Label>
                        <Form.Control
                          type="text"
                          value={roomName}
                          placeholder="กรอกชื่อห้อง"
                          onChange={(e) => setRoomName(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <span>ประเภทห้อง</span>
                        </Form.Label>
                        <Form.Select onChange={(e) => setCat(e.target.value)}>
                          {typeRoom ? (
                            typeRoom.map((r) => (
                              <option value={r.id} selected={r.id === cat}>
                                {r.name}
                              </option>
                            ))
                          ) : (
                            <option value=""></option>
                          )}
                        </Form.Select>
                      </Form.Group>
                      <Row>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>ราคา 2 คน</Form.Label>
                            <Form.Control
                              type="text"
                              value={twoPeople}
                              placeholder="0"
                              onChange={(e) => onChangeNumber(e, "twoPeople")}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>ราคา 3 คน</Form.Label>
                            <Form.Control
                              type="text"
                              value={threePeople}
                              placeholder="0"
                              onChange={(e) => onChangeNumber(e, "threePeople")}
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>ราคา 4 คน</Form.Label>
                            <Form.Control
                              type="text"
                              value={fourPeople}
                              placeholder="0"
                              onChange={(e) => onChangeNumber(e, "fourPeople")}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>ราคา 5 คน</Form.Label>
                            <Form.Control
                              type="text"
                              value={fivePeople}
                              placeholder="0"
                              onChange={(e) => onChangeNumber(e, "fivePeople")}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      {cat === "36ca416f-c719-4260-92cc-16ead0d5861e" ? (
                        <Row>
                          <Col>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>ราคา 6 คน</Form.Label>
                              <Form.Control
                                type="text"
                                value={sixPeople}
                                placeholder="0"
                                onChange={(e) => onChangeNumber(e, "sixPeople")}
                              />
                            </Form.Group>
                          </Col>
                          <Col></Col>
                        </Row>
                      ) : null}
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label>รายละเอียด</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={detail}
                          onChange={(e) => setDetail(e.target.value)}
                        />
                      </Form.Group>
                    </Form>
                  </Col>
                </Row>
              </div>
              <div className="p-2 mt-5">
                <Button
                  className="m-2"
                  style={{ width: "20%" }}
                  onClick={() => createRoom()}
                  disabled={cat === "" || roomName === ""}
                >
                  ยืนยัน
                </Button>
                <Link
                  to={{
                    pathname: "/admin/roomlist",
                  }}
                >
                  <Button
                    className="m-2"
                    variant="outline-secondary"
                    style={{ width: "20%" }}
                  >
                    ย้อนกลับ
                  </Button>
                </Link>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default RoomEdit;
