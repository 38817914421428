import React, { useEffect, useState } from "react";
import { Card, Container, Button, Col, Row, Form } from "react-bootstrap";
import "../../../../scss/roomList.scss";
import DatePicker from "react-datepicker";
import { Link, useNavigate } from "react-router-dom";
import OffEveryApi from "../../../../api/OffEveryApi";
import Swal from "sweetalert2";
import firstPage from "../../../../api/firstPage";
const TextPage = () => {
  const navigate = useNavigate();
  const [text, setText] = useState("");
  const [id, setId] = useState("");
  const [edit, setEdit] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("access-token") === null) {
      navigate("/admin/login");
    }
  }, []);
  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    const res = await firstPage.getText();
    if (res?.data?.elements) {
      setText(res?.data?.elements[0].text);
      setId(res?.data?.elements[0].id);
    } else {
      setEdit(false);
    }
  };
  const onSubmit = async () => {
    if (edit) {
      const res = await firstPage.updateText({
        id: id,
        text: text,
      });
      if (res) {
        alertSweet("สำเร็จ", "กำหนดสำเร็จ", "success");
      }
    } else {
      const res = await firstPage.createText({
        text: text,
      });
      if (res) {
        alertSweet("สำเร็จ", "กำหนดสำเร็จ", "success");
      }
    }
  };
  const alertSweet = (title, text, icon) => {
    Swal.fire({
      title: title,
      icon: icon,
      text: text,
    });
  };
  return (
    <div className="HomeAdmin">
      <Container>
        <div className="text-center">
          <br />
          <Card className="box-shadow mt-3">
            <Card.Body>
              <h3 className="mb-0 text-left">WHAT IS ESCAPE ROOM</h3>
              <br />
              <Row>
                <Form.Group className="mb-3 w-50 m-auto">
                  <Form.Label>WHAT IS ESCAPE ROOM</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                  />
                </Form.Group>
              </Row>
              <div className="p-2">
                <Button
                  className="m-2"
                  style={{ width: "40%" }}
                  onClick={() => onSubmit()}
                  // onClick={() => handleShow()}
                  // disabled={time.length === 0}
                >
                  ยืนยัน
                </Button>
                <Link
                  to={{
                    pathname: "/admin/setting",
                  }}
                >
                  <Button
                    className="m-2"
                    variant="outline-secondary"
                    style={{ width: "40%" }}
                  >
                    Back
                  </Button>
                </Link>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default TextPage;
