import React, { useEffect, useState } from "react";
import { Card, Container, Button, Image, Col, Row } from "react-bootstrap";
import "../../../../scss/roomList.scss";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faLeftLong } from "@fortawesome/free-solid-svg-icons";
import api from "../../../../api/queueApi";
import ModalDetail from "../../../../components/modal/modalDetail";
import { Link, useNavigate } from "react-router-dom";
import dayOffApi from "../../../../api/dayOffApi";
import Swal from "sweetalert2";
import { format } from "date-fns";
import paginationFactory from "react-bootstrap-table2-paginator";

const DayOffList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [dataShow, setDataShow] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("access-token") === null) {
      navigate("/admin/login");
    }
  }, []);

  useEffect(() => {
    getMasterData();
  }, []);

  const getMasterData = () => {
    dayOffApi
      .getAll()
      .then((r) => (r.data.elements ? setData(r.data.elements) : setData([])));
  };

  const alertSweet = (title, text, icon) => {
    Swal.fire({
      title: title,
      icon: icon,
      text: text,
    });
  };

  const onCheckStatus = (status) => {
    if (status === 200) {
      getMasterData();
      alertSweet("สำเร็จ", "ลบรายการสำเร็จ", "success");
    } else {
      alertSweet("ไม่สำเร็จ", "ลบรายการไม่สำเร็จ", "error");
    }
  };
  const onDelete = async (id) => {
    try {
      await dayOffApi.remove(id);
      onCheckStatus(200);
      getMasterData();
    } catch (error) {
      onCheckStatus(404);
    }
  };
  const actionFormat = (cell, row) => (
    <div>
      <FontAwesomeIcon
        icon={faTrash}
        color={"red"}
        className="set-cursor"
        onClick={() => onDelete(row.id)}
      />
    </div>
  );

  const dateFormat = (cell, row) => (
    <div>{format(new Date(row.date), "dd/MM/yyyy")}</div>
  );

  const columns = [
    {
      dataField: "date",
      text: "วันที่หยุด",
      formatter: dateFormat,
    },
    {
      dataField: "",
      text: "",
      formatter: actionFormat,
      align: "center",
      headerStyle: () => ({ width: "10%" }),
    },
  ];
  return (
    <div className="HomeAdmin">
      <Container>
        <div className="text-left">
          <Link
            to={{
              pathname: "/admin/home",
            }}
          >
            <Button className="mt-3">
              <FontAwesomeIcon
                icon={faLeftLong}
                style={{ fontSize: 14, marginRight: 5 }}
                className="set-cursor"
                color={"#fff"}
              />
              ย้อนกลับ
            </Button>
          </Link>
        </div>
        <div className="text-center">
          <br />
          <Card className="box-shadow mt-3">
            <Card.Body>
              <Row>
                <Col>
                  <h3 className="mb-0 text-left">รายการวันหยุด</h3>
                </Col>
                <Col style={{ textAlign: "right" }}>
                  <Link
                    to={{
                      pathname: "/admin/dayoff",
                    }}
                  >
                    <Button>เพิ่ม</Button>
                  </Link>
                </Col>
              </Row>

              <br />
              <BootstrapTable
                keyField="id"
                data={data}
                columns={columns}
                pagination={paginationFactory({ sizePerPage: 2 })}
              />
            </Card.Body>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default DayOffList;
