import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import menu from "../../assets/icon/menu.svg";

const NavBar = ({ location }) => {
  const navigate = useNavigate();
  const [logout, setLogout] = useState(false);

  useEffect(() => {
    if (window.location.href !== location) {
      window.location.reload();
    }
  }, [window.location.href]);

  useEffect(() => {
    if (localStorage.getItem("access-token") !== null) {
      setLogout(true);
    }
  }, []);

  const onSelectFuc = () => {
    if (logout) {
      localStorage.clear();
      navigate("/admin/login");
    } else {
      navigate("/admin/login");
    }
  };
  const scroll = (val) => {
    setTimeout(() => {
      document.getElementById(val).scrollIntoView({ behavior: "smooth" });
      setShow(false);
    }, 300);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const toggleShow = () => setShow((s) => !s);

  return (
    <Navbar className="nav-style" sticky="top" expand="lg">
      <Container className="menu-desktop">
        <Nav className="m-auto text">
          <Nav.Link
            activeClass="active"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onClick={() => scroll("escaperooms")}
            className="text-nav"
            style={{ color: " #fff" }}
          >
            ESCAPE ROOMS
          </Nav.Link>
          <Nav.Link
            activeClass="active"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onClick={() => scroll("promotion")}
            className="text-nav"
            style={{ color: " #fff" }}
          >
            PROMOTION
          </Nav.Link>
          <Nav.Link
            activeClass="active"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onClick={() => scroll("review")}
            className="text-nav"
            style={{ color: " #fff" }}
          >
            REVIEW US
          </Nav.Link>
          <Nav.Link
            activeClass="active"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onClick={() => scroll("contact")}
            className="text-nav"
            style={{ color: " #fff" }}
          >
            CONTACT
          </Nav.Link>
          <Nav.Link
            activeClass="active"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onClick={() => scroll("news")}
            className="text-nav"
            style={{ color: " #fff" }}
          >
            NEWS
          </Nav.Link>
          <Nav.Link
            activeClass="active"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onClick={() => scroll("franchise")}
            className="text-nav"
            style={{ color: " #fff" }}
          >
            Team Building
          </Nav.Link>
        </Nav>
      </Container>
      <div className="menu-mobile">
        <div onClick={toggleShow}>
          <img src={menu} alt="menu"></img>
        </div>
        <Offcanvas show={show} onHide={handleClose} placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title></Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="m-auto text">
              <Nav.Link
                activeClass="active"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={() => scroll("escaperooms")}
                className="text-nav"
                style={{ color: " #fff" }}
              >
                ESCAPE ROOMS
              </Nav.Link>
              <Nav.Link
                activeClass="active"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={() => scroll("promotion")}
                className="text-nav"
                style={{ color: " #fff" }}
              >
                PROMOTION
              </Nav.Link>
              <Nav.Link
                activeClass="active"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={() => scroll("review")}
                className="text-nav"
                style={{ color: " #fff" }}
              >
                REVIEW US
              </Nav.Link>
              <Nav.Link
                activeClass="active"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={() => scroll("contact")}
                className="text-nav"
                style={{ color: " #fff" }}
              >
                CONTACT
              </Nav.Link>
              <Nav.Link
                activeClass="active"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={() => scroll("news")}
                className="text-nav"
                style={{ color: " #fff" }}
              >
                NEWS
              </Nav.Link>
              <Nav.Link
                activeClass="active"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={() => scroll("franchise")}
                className="text-nav"
                style={{ color: " #fff" }}
              >
                Team Building
              </Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </Navbar>
  );
};

export default NavBar;
