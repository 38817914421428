import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Image,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import "../../../../../scss/roomList.scss";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLeftLong,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import api from "../../../../../api/roomApi";
import Swal from "sweetalert2";
import paginationFactory from "react-bootstrap-table2-paginator";
import firstPage from "../../../../../api/firstPage";

const NewsList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    getMasterData();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("access-token") === null) {
      navigate("/admin/login");
    }
  }, []);

  const getMasterData = () => {
    firstPage
      .getNews()
      .then((r) => (r.data.elements ? setData(r.data.elements) : setData([])));
  };

  const onDelete = async (id) => {
    try {
      await firstPage.removeNews(id);
      onCheckStatus(200);
      getMasterData();
    } catch (error) {
      onCheckStatus(404);
    }
  };

  const onCheckStatus = (status) => {
    if (status === 200) {
      getMasterData();
      alertSweet("สำเร็จ", "ลบรายการสำเร็จ", "success");
    } else {
      alertSweet("ไม่สำเร็จ", "ลบรายการไม่สำเร็จ", "error");
    }
  };

  const alertSweet = (title, text, icon) => {
    Swal.fire({
      title: title,
      icon: icon,
      text: text,
    });
  };

  const actionFormat = (cell, row) => (
    <div>
      <Link
        to={{
          pathname: "/admin/setting/news/edit",
          // search: `?id=${row.id}`
        }}
        state={{
          id: row.id,
          type: "edit",
        }}
      >
        <FontAwesomeIcon
          icon={faPenToSquare}
          color={"#FFCC00"}
          style={{ marginRight: 20 }}
          className="set-cursor"
        />
      </Link>
      <FontAwesomeIcon
        icon={faTrash}
        color={"red"}
        className="set-cursor"
        onClick={() => onDelete(row.id)}
      />
    </div>
  );

  const imageFormat = (cell, row) => (
    <div>
      <Image
        className="image-scale"
        src={row.imageUrl}
        style={{ objectFit: "cover", overflow: "hidden", cursor: "pointer" }}
      />
    </div>
  );

  function TriggerRendererProp(row) {
    return (
      <OverlayTrigger
        placement="left"
        overlay={<Tooltip id="button-tooltip-2">{row.description}</Tooltip>}
      >
        {({ ref, ...triggerHandler }) => (
          <p {...triggerHandler} ref={ref} className="ms-1 crop-only">
            {row.description}
          </p>
        )}
      </OverlayTrigger>
    );
  }

  const descriptionFormat = (cell, row) => (
    <div>{TriggerRendererProp(row)}</div>
  );

  const columns = [
    {
      dataField: "title",
      text: "หัวข้อ",
    },
    {
      dataField: "detail",
      text: "รายละเอียด",
    },
    {
      dataField: "image",
      text: "รูปข่าว",
      formatter: imageFormat,
    },
    {
      dataField: "",
      text: "",
      formatter: actionFormat,
      align: "center",
      headerStyle: () => ({ width: "10%" }),
    },
  ];
  return (
    <div className="HomeAdmin">
      <Container>
        <div className="text-left">
          <Link
            to={{
              pathname: "/admin/setting",
            }}
          >
            <Button className="mt-3">
              <FontAwesomeIcon
                icon={faLeftLong}
                style={{ fontSize: 14, marginRight: 5 }}
                className="set-cursor"
                color={"#fff"}
              />
              ย้อนกลับ
            </Button>
          </Link>
        </div>
        <div className="text-center">
          <br />
          <Card className="box-shadow mt-3">
            <Card.Body>
              <Row>
                <Col>
                  <h3 className="mb-0 text-left">รายการการข่าว</h3>
                </Col>
                <Col style={{ textAlign: "right" }}>
                  <Link
                    to={{
                      pathname: "/admin/setting/news/edit",
                    }}
                    state={{
                      type: "add",
                    }}
                  >
                    <Button>เพิ่ม</Button>
                  </Link>
                </Col>
              </Row>
              <br />
              <BootstrapTable
                keyField="id"
                data={data}
                columns={columns}
                pagination={paginationFactory({ sizePerPage: 5 })}
              />
            </Card.Body>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default NewsList;
