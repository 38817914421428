import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import "../../../../scss/homeAdmin.scss";

const HomeAdmin = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("access-token") === null) {
      navigate("/admin/login");
    }
  }, []);

  return (
    <div className="HomeAdmin">
      <Container>
        <div className="text-center">
          <Row>
            <Col>
              <Link
                to={{
                  pathname: "/admin/roomlist",
                }}
              >
                <Button
                  variant="outline-primary"
                  className="w-75 text-home-admin"
                  style={{ height: 70, marginTop: "10%" }}
                >
                  จัดการห้อง
                </Button>
              </Link>
            </Col>
            <Col>
              <Link
                to={{
                  pathname: "/admin/queuelist",
                }}
              >
                <Button
                  variant="outline-success"
                  className="w-75 mt-5 text-home-admin"
                  style={{ height: 70 }}
                >
                  ตรวจสอบการจองคิว
                </Button>
              </Link>
            </Col>
            <Col>
              <Link
                to={{
                  pathname: "/admin/dayofflist",
                }}
              >
                <Button
                  variant="outline-warning"
                  className="w-75 mt-5 text-home-admin"
                  style={{ height: 70 }}
                >
                  วันหยุด
                </Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col>
              <Link
                to={{
                  pathname: "/admin/offevery",
                }}
              >
                <Button
                  variant="outline-danger"
                  className="w-75 mt-5 text-home-admin"
                  style={{ height: 70 }}
                >
                  วันหยุดของสัปดาห์
                </Button>
              </Link>
            </Col>
            <Col>
              <Link
                to={{
                  pathname: "/admin/promotion",
                }}
              >
                <Button
                  variant="outline-info"
                  className="w-75 mt-5 text-home-admin"
                  style={{ height: 70 }}
                >
                  โปรโมชั่น
                </Button>
              </Link>
            </Col>
            <Col>
              <Link
                to={{
                  pathname: "/admin/setting",
                }}
              >
                <Button
                  variant="outline-secondary"
                  className="w-75 mt-5 text-home-admin"
                  style={{ height: 70 }}
                >
                  จัดการหน้าเว็บ
                </Button>
              </Link>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default HomeAdmin;
