import React, { useEffect, useState } from "react";
import { Card, Container, Button } from "react-bootstrap";
import "../../../../scss/roomList.scss";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faLeftLong, faTrash } from "@fortawesome/free-solid-svg-icons";
import api from "../../../../api/queueApi";
import ModalDetail from "../../../../components/modal/modalDetail";
import { Link, useNavigate } from "react-router-dom";
import dayOffApi from "../../../../api/dayOffApi";
import Swal from "sweetalert2";

const QueueList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [dataShow, setDataShow] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("access-token") === null) {
      navigate("/admin/login");
    }
  }, []);

  useEffect(() => {
    getMasterData();
  }, []);

  const getMasterData = () => {
    api
      .getAll()
      .then((r) => (r.data.elements ? setData(r.data.elements) : setData([])));
  };
  const handleClose = () => setShow(false);
  const handleShow = (row) => {
    setDataShow({
      dateTime: row.dateTime,
      email: row.email,
      firstName: row.firstName,
      lastName: row.lastName,
      nickName: row.nickName,
      amount: row.amount,
      tel: row.tel,
      roomName: row.roomName,
      time: row.dateTimeDTOList,
    });
    setShow(true);
  };

  const actionFormat = (cell, row) => (
    <div>
      <FontAwesomeIcon
        icon={faEye}
        onClick={() => handleShow(row)}
        style={{ fontSize: 20 }}
        className="set-cursor"
        color={"#0d6efd"}
      />
      <FontAwesomeIcon
        icon={faTrash}
        color={"red"}
        className="set-cursor"
        onClick={() => onDelete(row.id)}
        style={{ marginLeft: 10 }}
      />
    </div>
  );

  const onDelete = async (id) => {
    try {
      await api.remove(id);
      onCheckStatus(200);
      getMasterData();
    } catch (error) {
      onCheckStatus(404);
    }
  };

  const alertSweet = (title, text, icon) => {
    Swal.fire({
      title: title,
      icon: icon,
      text: text,
    });
  };

  const onCheckStatus = (status) => {
    if (status === 200) {
      getMasterData();
      alertSweet("สำเร็จ", "ลบรายการสำเร็จ", "success");
    } else {
      alertSweet("ไม่สำเร็จ", "ลบรายการไม่สำเร็จ", "error");
    }
  };

  const statusPaymentFormat = (cell, row) => (
    <div>
      <label
        style={
          row.statusPayment === "reject"
            ? { color: "red" }
            : row.statusPayment === "waiting"
            ? { color: "#FFCC00" }
            : { color: "green" }
        }
      >
        {row.statusPayment === "reject"
          ? "จ่ายเงินไม่สำเร็จ"
          : row.statusPayment === "waiting"
          ? "รอชำระเงิน"
          : "จ่ายเงินสำเร็จ"}
      </label>
    </div>
  );

  const columns = [
    // {
    //   dataField: "no",
    //   text: "No.",
    // },
    {
      dataField: "firstName",
      text: "ชื่อ",
      headerStyle: () => ({width: "10%"}),
      formatter: (cell, row) => {
        if (row.firstName) {
          return row.firstName;
        } else if (row.nickName) {
          return row.nickName;
        } else {
          return row.lastName;
        }
      }
    },
    {
      dataField: "email",
      text: "อีเมล",
    },
    {
      dataField: "roomName",
      text: "Room Name",
      // formatter: roomNameFormat,
    },
    {
      dataField: "dateTime",
      text: "วันที่จอง",
      sort: true, // ทำให้คอลัมน์นี้สามารถเรียงได้
      sortFunc: (a, b, order, dataField) => {
        // แปลงวันที่จาก "MM/DD/YYYY" เป็น Date object
        const dateA = new Date(a.split('/').reverse().join('/'));
        const dateB = new Date(b.split('/').reverse().join('/'));

        if (order === 'asc') {
          return dateB - dateA; // เรียงจากวันที่ล่าสุดไปยังวันที่เก่าสุด
        }
        return dateA - dateB;
      },
      // formatter: dateFormat,
    },
    {
      dataField: "statusPayment",
      text: "สถานะการจ่ายเงิน",
      formatter: statusPaymentFormat,
    },
    {
      dataField: "",
      text: "",
      formatter: actionFormat,
      align: "center",
      headerStyle: () => ({ width: "10%" }),
    },
  ];

  const defaultSorted = [{
    dataField: 'dateTime',
    order: 'asc' // เรียงจากวันที่ล่าสุดมาก่อน
  }];

  return (
    <div className="HomeAdmin">
      <Container>
        <div className="text-left">
          <Link
            to={{
              pathname: "/admin/home",
            }}
          >
            <Button className="mt-3">
              <FontAwesomeIcon
                icon={faLeftLong}
                style={{ fontSize: 14, marginRight: 5 }}
                className="set-cursor"
                color={"#fff"}
              />
              ย้อนกลับ
            </Button>
          </Link>
        </div>
        <ModalDetail
          show={show}
          data={dataShow}
          title="รายละเอียดการจอง"
          handleClose={() => handleClose()}
        />
        <div className="text-center">
          <br />
          <Card className="box-shadow mt-3">
            <Card.Body>
              <h3 className="mb-0 text-left">รายการการจองห้อง</h3>
              <br />
              <BootstrapTable keyField="id" data={data} columns={columns} defaultSorted={defaultSorted} />
            </Card.Body>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default QueueList;
