import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const NavBarHome = ({ location }) => {
  const navigate = useNavigate();
  const [logout, setLogout] = useState(false);

  useEffect(() => {
    if (
      location === "https://www.escaperoombangkok.com/" &&
      location === "http://localhost:3000/"
    ) {
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("access-token") !== null) {
      setLogout(true);
    }
  }, []);

  const onSelectFuc = () => {
    if (logout) {
      localStorage.clear();
      navigate("/admin/login");
    } else {
      navigate("/admin/login");
    }
  };
  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="/">DEATHAWARENESSCAFE</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto" style={{ marginLeft: "100%" }}>
            <Nav.Link onClick={() => onSelectFuc()}>
              {logout ? "Logout" : "Admin"}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBarHome;
