import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const test = [
  {
    bookingId: "6d8737dc-61e7-4c64-bc45-955c10b1c33b",
    dateTimeId: "feae256c-85dc-4224-806e-ba24c254d8a9",
    dateTimeName: "17.00",
    id: "89e9832d-5d7f-496a-b355-231bda665e71",
    invoiceNo: "",
  },
  {
    bookingId: "6d8737dc-61e7-4c64-bc45-955c10b1c332",
    dateTimeId: "feae256c-85dc-4224-806e-ba24c254d8a2",
    dateTimeName: "18.00",
    id: "89e9832d-5d7f-496a-b355-231bda665e72",
    invoiceNo: "",
  },
  {
    bookingId: "6d8737dc-61e7-4c64-bc45-955c10b1c333",
    dateTimeId: "feae256c-85dc-4224-806e-ba24c254d8a3",
    dateTimeName: "19.00",
    id: "89e9832d-5d7f-496a-b355-231bda665e73",
    invoiceNo: "",
  },
];
function ModalDetail(props) {
  const data = props.data;
  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>ชื่อเล่น: {data.nickName}</p>
          <p>ชื่อผู้จอง: {data.firstName + data.lastName}</p>
          <p>ชื่อห้อง: {data.roomName}</p>
          <p>วันที่จอง: {data.dateTime}</p>
          <p>อีเมล: {data.email}</p>
          <p>เบอร์โทร: {data.tel}</p>
          <p>ราคา: {data.amount}</p>
          <p>
            เวลา:{" "}
            {data.time
              ? data.time
                  .sort((a, b) => (a.time > b.time ? 1 : -1))
                  .map((r) => r.dateTimeName + ",")
              : "ไม่มี"}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={props.handleClose}>
            ยืนยัน
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalDetail;
